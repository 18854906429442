import { render, staticRenderFns } from "./TwitchView.vue?vue&type=template&id=bc405252&scoped=true"
import script from "./TwitchView.vue?vue&type=script&lang=js"
export * from "./TwitchView.vue?vue&type=script&lang=js"
import style0 from "./TwitchView.vue?vue&type=style&index=0&id=bc405252&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc405252",
  null
  
)

export default component.exports