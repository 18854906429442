import { render, staticRenderFns } from "./DesignView.vue?vue&type=template&id=7fb2ede6&scoped=true"
import script from "./DesignView.vue?vue&type=script&lang=js"
export * from "./DesignView.vue?vue&type=script&lang=js"
import style0 from "./DesignView.vue?vue&type=style&index=0&id=7fb2ede6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb2ede6",
  null
  
)

export default component.exports